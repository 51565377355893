/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0'

import ErrorPage from 'views/Pages/ErrorPage'
import UnauthorizedPage from 'views/Pages/UnauthorizedPage'
import PIIDataAccess from 'views/PIIDataAccess/DataAccess'

const hist = createBrowserHistory()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (error.response?.status === 404) return false
        else if (error.response?.status === 401) hist.push('/unauthorized')
        else if (failureCount < 2) return true
        else return false
      }
    },
    mutations: {
      onError: (err, variables, rollback) =>
        typeof rollback === 'function' ? rollback() : err.response?.status === 401 && !localStorage.isPii ? hist.push('/unauthorized') : null
    }
  }
})

ReactDOM.render(
  <Router history={hist}>
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route path="/pii/supplier" component={() => <PIIDataAccess hist={hist} />} />
        <Route exact path="/unauthorized" component={UnauthorizedPage} />
        <Route path="/" component={ErrorPage} />
      </Switch>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Router>,
  document.getElementById('root')
)
