import { InvoiceProcess, NameComparisonCodes } from './Constants'
import stringSimilarity from 'string-similarity'

function changeLoadingDiv(show) {
  let loadingDiv = document.getElementById('loadingDiv')
  if (loadingDiv) {
    if (show) {
      loadingDiv.style.display = 'block'
    } else {
      loadingDiv.style.display = 'none'
    }
  }
}

function checkErrorOrWarning(validationError, validated = false) {
  if (validated) return ''

  if (typeof validationError === 'string' && validationError !== '')
    return 'error'

  if (Array.isArray(validationError) && validationError.length > 0) {
    let warning = false
    let validation
    for (validation of validationError) {
      if (validation.type && validation.type === 'warning') {
        warning = true
      } else {
        return 'error'
      }
    }
    return warning ? 'warning' : 'error'
  }
  return ''
}

function checkExpiration(data) {
  let endDate = new Date(data.endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  let currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  let timeBetween =
    new Date(endDate).getTime() - new Date(currentDate).getTime()
  let daysBetween = timeBetween / (1000 * 3600 * 24)
  let enabled = false
  if (daysBetween >= 0) {
    enabled = true
  }

  return {
    enabled: enabled,
    daysBetween: daysBetween,
    suscriptionType: data.suscriptionType
  }
}

const decodeBase64 = (data) => Buffer.from(data, 'base64').toString();

function formatDate({ date, options = { timeZoneName: 'short' } }) {
  return new Date(date).toLocaleString(
    window.navigator.userLanguage || window.navigator.language,
    options
  )
}

/**
 * Get the tenant of the user
 */
function getUserTenant() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.metadata.tenantId
}

/**
 * Get the roles of the user
 */
function getUserRoles() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.roles
}

/**
 * Get the roles of the user
 */
function getUserInfo() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo
}

/**
 * Get the login method
 */
function getLoginMethod() {
  let loginMethod = localStorage.getItem('login.method')
  return loginMethod
}

const handleRequestError = error => {
  return {
    success: false,
    error: (error.response && error.response.data) || error.response || error
  }
}

function showLoading(callback) {
  changeLoadingDiv(true)
  sessionStorage.setItem('isLoading', true)
  if (callback) {
    callback(true)
  }
}
function hideLoading(callback) {
  changeLoadingDiv(false)
  sessionStorage.removeItem('isLoading')
  if (callback) {
    callback(false)
  }
}

function getExtensionFile(nameFile) {
  const regex = /^([^\\]*)\.(\w+)$/
  var matches = nameFile?.match(regex)
  if (matches) {
    return matches[2].toLowerCase()
  }
}

function incrementAlphanumeric(str, increment = true) {
  const numPart = str.match(/(0?[1-9])+$|0?([1-9]+?0+)$/)[0]
  const strPart = str.slice(0, str.indexOf(numPart))
  const isLastIndexNine = numPart.match(/9$/)

  if (isLastIndexNine || strPart != null) {
    return strPart + numPart.replace(/\d+$/, n => (increment ? ++n : --n))
  } else {
    return strPart + '0' + numPart.replace(/\d+$/, n => (increment ? ++n : --n))
  }
}

function countDecimals(value) {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1]?.length || 0
  }
  return 0
}

export function maxDecimalAndSummation(values) {
  let summation = values.reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const maxDecimals = values.map(currentValue => {
    return countDecimals(Number(currentValue ?? 0))
  })

  const maxDecimalNo = maxDecimals.length > 0 ? Math.max(...maxDecimals) : 0
  summation = Number(summation.toFixed(maxDecimalNo))

  return { summation, maxDecimalNo }
}

function getInvoiceProcess(appConfig) {
  const process = {}
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(InvoiceProcess)) {
    if (appConfig && appConfig.params[key] && appConfig.params[key].enabled) {
      process[key] = value
    }
  }
  process['ocr-global'] = InvoiceProcess['ocr-global']
  return process
}

function normalizeAndUpperCase(input) {
  return input.toUpperCase().normalize('NFKD')
}

function supplierNameMatcher(piiFormName, aribaQstName, warningThreshold, passThreshold, isDBANameComparison) {
  let similarity = 0
  let message = ''
  let code = ''

  let firstString = piiFormName || ''
  let secondString = aribaQstName || ''

  firstString = normalizeAndUpperCase(firstString)
  secondString = normalizeAndUpperCase(secondString)

  similarity = stringSimilarity.compareTwoStrings(firstString, secondString)
  similarity = Math.round(similarity * 100) / 100

  const processedName = isDBANameComparison ? 'DBA name' : 'name'

  if (similarity >= passThreshold) {
    message = `The supplier’s name match with the ${processedName} registered in the system`
    code = isDBANameComparison ? NameComparisonCodes.DBA_NAME.MATCH : NameComparisonCodes.LEGAL_NAME.MATCH
  } else if (similarity >= warningThreshold) {
    message = ` The supplier’s name provided partially match with the ${processedName} registered in the system`
    message += `\n  Name Registered: ${aribaQstName}`
    message += `\n  Name Provider by Supplier: ${piiFormName}`
    code = isDBANameComparison ? NameComparisonCodes.DBA_NAME.PARTIAL_MATCH : NameComparisonCodes.LEGAL_NAME.PARTIAL_MATCH
  } else {
    message = ` The supplier’s name provided does not match with the ${processedName} registered in the system`
    message += `\n  Name Registered: ${aribaQstName}`
    message += `\n  Name Provider by Supplier: ${piiFormName}`
    code = isDBANameComparison ? NameComparisonCodes.DBA_NAME.NOT_MATCH : NameComparisonCodes.LEGAL_NAME.NOT_MATCH
  }

  return {
    similarity: similarity,
    message,
    code,
    piiFormName,
    aribaQstName
  }
}

function accountHolderNameComparison(piiFormAccountHolderName, aribaQstName, aribaQstDbaName, warningThreshold, passThreshold) {
  let legalNameSimilarity = 0
  let dbaNameSimilarity = 0
  let message = ''
  let code = ''

  let firstString = piiFormAccountHolderName || ''
  let secondString = aribaQstName || ''
  let thirdString = aribaQstDbaName || ''

  firstString = normalizeAndUpperCase(firstString)
  secondString = normalizeAndUpperCase(secondString)
  thirdString = normalizeAndUpperCase(thirdString)

  legalNameSimilarity = stringSimilarity.compareTwoStrings(firstString, secondString)
  legalNameSimilarity = Math.round(legalNameSimilarity * 100) / 100

  if (legalNameSimilarity >= passThreshold) {
    message = `The bank account holder name match with the supplier’s name registered in the system`
    code = NameComparisonCodes.ACCOUNT_HOLDER_NAME.MATCH
  } else if (legalNameSimilarity >= warningThreshold) {
    message = ` The bank account holder name provided partially match with the supplier’s name registered in the system`
    message += `\n  Name Registered: ${aribaQstName}`
    message += `\n  Bank Account Holder Name Provider by Supplier: ${piiFormAccountHolderName}`
    code = NameComparisonCodes.ACCOUNT_HOLDER_NAME.MATCH
  } else if (thirdString) {
    // Execute comparison with DBA Name
    dbaNameSimilarity = stringSimilarity.compareTwoStrings(firstString, thirdString)
    dbaNameSimilarity = Math.round(dbaNameSimilarity * 100) / 100

    if (dbaNameSimilarity >= passThreshold) {
      message = `The bank account holder name match with the supplier’s DBA name registered in the system`
      code = NameComparisonCodes.ACCOUNT_HOLDER_NAME.MATCH
    }
    else if (dbaNameSimilarity >= warningThreshold) {
      message = ` The bank account holder name provided partially match with the supplier’s DBA name registered in the system`
      message += `\n  DBA Name Registered: ${aribaQstDbaName}`
      message += `\n  Bank Account Holder Name Provider by Supplier: ${piiFormAccountHolderName}`
      code = NameComparisonCodes.ACCOUNT_HOLDER_NAME.MATCH
    }
    else {
      // No match with Legal name or Dba Name
      message = ` The bank account holder name provided does not match with the supplier's name or the DBA Name registered in the system.`
      message += `\n  Name Registered: ${aribaQstName}`
      message += `\n  DBA Name Registered: ${aribaQstDbaName}`
      message += `\n  Bank Account Holder Name Provider by Supplier: ${piiFormAccountHolderName}`
      code = NameComparisonCodes.ACCOUNT_HOLDER_NAME.NOT_MATCH
    }
  }
  else {
    message = ` The bank account holder name provided does not match with the supplier's name registered in the system.`
    message += `\n  Name Registered: ${aribaQstName}`
    message += `\n  Bank Account Holder Name Provider by Supplier: ${piiFormAccountHolderName}`
    code = NameComparisonCodes.ACCOUNT_HOLDER_NAME.NOT_MATCH
  }

  return {
    similarity: legalNameSimilarity >= dbaNameSimilarity ? legalNameSimilarity : dbaNameSimilarity,
    message,
    code,
    piiFormAccountHolderName,
    aribaQstName: legalNameSimilarity >= dbaNameSimilarity ? aribaQstName : aribaQstDbaName
  }
}

export {
  accountHolderNameComparison,
  checkErrorOrWarning,
  checkExpiration,
  decodeBase64,
  formatDate,
  getExtensionFile,
  getInvoiceProcess,
  getLoginMethod,
  getUserInfo,
  getUserRoles,
  getUserTenant,
  handleRequestError,
  hideLoading,
  incrementAlphanumeric,
  showLoading,
  supplierNameMatcher
}
