import React, { useEffect, useRef, useState } from 'react'

import DataAccessForm from './DataAccessForm'
import DataAccessLogin from './DataAccessLogin'
import { Route, Router, Switch } from 'react-router-dom'
import RelishMessage from 'views/RelishMessages/RelishMessageView'
export default function PIIDataAccess (props) {
  const [clientId, setClientId] = useState(undefined)
  const [environmentId, setEnvironmentId] = useState(undefined)
  const [validationId, setValidationId] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [bistroPiiTransaction, setBistroPiiTransaction] = useState(undefined)
  const [supplierId, setSupplierId] = useState(undefined)
  const [supplierName, setSupplierName] = useState(undefined)
  const [dbaName, setDBAName] = useState(undefined)
  const [isFormUpdate, setIsFormUpdate] = useState(undefined)
  const [urlToken, setUrlToken] = useState(false)
  const [appId, setAppId] = useState(undefined)
  const [typeId, setTypeId] = useState(undefined)
  const [message, setMessage] = useState(undefined)
  const [startWithW9UploadForm, setStartWithW9UploadForm] = useState('false')
  const [alwaysAllowTin, setAlwaysAllowTin] = useState('false')
  const [formConfigurations, setFormconfigurations] = useState(undefined)
  const [callBackFunction, setCallBackFunction] = useState(undefined)

  const [formUpdateValidation, setFormUpdateValidation] = useState(undefined)
  const isMounted = useRef(false)

  const [bankAccountOwnershipConfigurations, setBankAccountOwnershipConfigurations] = useState(undefined)
  const [giactConfigurations, setGiactConfigurations] = useState(undefined)
  const [additionalFileInstructions, setAdditionalFileInstructions] = useState(undefined)
  const [maxAdditionalFiles, setMaxAdditionalFiles] = useState(undefined)
  const [minAdditionalFiles, setMinAdditionalFiles] = useState(undefined)
  const [uploadBankDocumentationEnabled, setUploadBankDocumentationEnabled] = useState(undefined)
  const [uploadTaxDocumentationEnabled, setUploadTaxDocumentationEnabled] = useState(undefined)
  const [sectionConfigurations, setSectionConfigurations] = useState(undefined)
  const [bankSectionEnabled, setBankSectionEnabled] = useState(undefined)
  const [bankValidationRequired, setBankValidationRequired] = useState(undefined)
  const [requireBankAccountHolder ,setRequireBankAccountHolder] = useState(undefined)
  const [bankAccountOwnershipValidationEnabled ,setBankAccountOwnershipValidationEnabled] = useState(undefined)
  const [bankAccountOwnershipValidationProvider ,setBankAccountOwnershipValidationProvider] = useState(undefined)
  const [taxSectionEnabled, setTaxSectionEnabled] = useState(undefined)
  const [taxValidationRequired, setTaxValidationRequired] = useState(undefined)
  const [attachmentSectionEnabled, setAttachmentSectionEnabled] = useState(undefined)
  const [mandatoryFieldsConfiguration, setMandatoryFieldsConfiguration] = useState(undefined)
  const [accountTypeField, setAccountTypeField] = useState(undefined)
  const [accountNumberField, setAccountNumberField] = useState(undefined)
  const [ibanNumberField, setIbanNumberField] = useState(undefined)
  const [routingNumberField, setRoutingNumberField] = useState(undefined)
  const [swiftCodeField, setSwiftCodeField] = useState(undefined)
  const [ssnField, setSSNField] = useState(undefined)
  const [einField, setEINField] = useState(undefined)
  const [useTINForNonUSPII, setUseTINForNonUSPII] = useState(undefined)
  const [includeBankAccountType, setIncludeBankAccountType] = useState(undefined)
  const [maxAttemptsTax, setMaxAttemptsTax] = useState(undefined)
  const [maxAttemptsBank, setMaxAttemptsBank] = useState(undefined)
  const [allowOtherNonValidatedTaxID, setAllowOtherNonValidatedTaxID] = useState(undefined)
  const [performNameComparison, setPerformNameComparison] = useState(undefined)
  const [nameComparisonPassThreshold, setNameComparisonPassThreshold] = useState(undefined)
  const [nameComparisonWarningThreshold, setNameComparisonWarningThreshold] = useState(undefined)
  /**
   *
   *
   *
   */
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
    }
    // this function will be ran when the component is re-renderd or unmounted
    return () => {}
    //you need to add in this array the properties that need to be checked for changes and the ones you will be using in this function
    //, if one property changes, the useEffect function will be ran again (once per DOM change)
    // you can send all the props and if something updated this function will be ran
  })

  return (
    <Router history={props.hist}>
      <Switch>
        <Route
          exact
          path="/pii/supplier/login"
          component={props => {
            return (
              <DataAccessLogin
                {...props}
                clientId={clientId}
                environmentId={environmentId}
                setClientId={setClientId}
                setEnvironmentId={setEnvironmentId}
                validationId={validationId}
                setValidationId={setValidationId}
                email={email}
                setEmail={setEmail}
                bistroPiiTransaction={bistroPiiTransaction}
                setBistroPiiTransaction={setBistroPiiTransaction}
                supplierId={supplierId}
                setSupplierId={setSupplierId}
                supplierName={supplierName}
                setSupplierName={setSupplierName}
                dbaName={dbaName}
                setDBAName={setDBAName}
                isFormUpdate={isFormUpdate}
                setIsFormUpdate={setIsFormUpdate}
                urlToken={urlToken}
                setUrlToken={setUrlToken}
                formUpdateValidation={formUpdateValidation}
                setFormUpdateValidation={setFormUpdateValidation}
                appId={appId}
                setAppId={setAppId}
                setTypeId={setTypeId}
                typeId={typeId}
                setMessage={setMessage}
                message={message}
                setStartWithW9UploadForm={setStartWithW9UploadForm}
                startWithW9UploadForm={startWithW9UploadForm}
                setAlwaysAllowTin={setAlwaysAllowTin}
                alwaysAllowTin={alwaysAllowTin}
                setFormconfigurations={setFormconfigurations}
                formConfigurations={formConfigurations}
                callBackFunction={callBackFunction}
                setCallBackFunction={setCallBackFunction}
                bankAccountOwnershipConfigurations={bankAccountOwnershipConfigurations}
                setBankAccountOwnershipConfigurations={setBankAccountOwnershipConfigurations}
                giactConfigurations={giactConfigurations}
                setGiactConfigurations={setGiactConfigurations}
                setAdditionalFileInstructions={setAdditionalFileInstructions}
                additionalFileInstructions={additionalFileInstructions}
                setMaxAdditionalFiles={setMaxAdditionalFiles}
                maxAdditionalFiles={maxAdditionalFiles}
                setMinAdditionalFiles={setMinAdditionalFiles}
                minAdditionalFiles={minAdditionalFiles}
                uploadBankDocumentationEnabled={uploadBankDocumentationEnabled}
                setUploadBankDocumentationEnabled={setUploadBankDocumentationEnabled}
                uploadTaxDocumentationEnabled={uploadTaxDocumentationEnabled}
                setUploadTaxDocumentationEnabled={setUploadTaxDocumentationEnabled}
                sectionConfigurations={sectionConfigurations}
                setSectionConfigurations={setSectionConfigurations}
                bankSectionEnabled={bankSectionEnabled}
                setBankSectionEnabled={setBankSectionEnabled}
                bankValidationRequired={bankValidationRequired}
                setBankValidationRequired={setBankValidationRequired}
                requireBankAccountHolder={requireBankAccountHolder}
                setRequireBankAccountHolder={setRequireBankAccountHolder}
                bankAccountOwnershipValidationEnabled={bankAccountOwnershipValidationEnabled}
                setBankAccountOwnershipValidationEnabled={setBankAccountOwnershipValidationEnabled}
                bankAccountOwnershipValidationProvider={bankAccountOwnershipValidationProvider}
                setBankAccountOwnershipValidationProvider={setBankAccountOwnershipValidationProvider}
                taxSectionEnabled={taxSectionEnabled}
                setTaxSectionEnabled={setTaxSectionEnabled}
                taxValidationRequired={taxValidationRequired}
                setTaxValidationRequired={setTaxValidationRequired}
                attachmentSectionEnabled={attachmentSectionEnabled}
                setAttachmentSectionEnabled={setAttachmentSectionEnabled}
                mandatoryFieldsConfiguration={mandatoryFieldsConfiguration}
                setMandatoryFieldsConfiguration={setMandatoryFieldsConfiguration}
                accountTypeField={accountTypeField}
                setAccountTypeField={setAccountTypeField}
                accountNumberField={accountNumberField}
                setAccountNumberField={setAccountNumberField}
                ibanNumberField={ibanNumberField}
                setIbanNumberField={setIbanNumberField}
                routingNumberField={routingNumberField}
                setRoutingNumberField={setRoutingNumberField}
                swiftCodeField={swiftCodeField}
                setSwiftCodeField={setSwiftCodeField}
                ssnField={ssnField}
                setSSNField={setSSNField}
                einField={einField}
                setEINField={setEINField}
                useTINForNonUSPII={useTINForNonUSPII}
                setUseTINForNonUSPII={setUseTINForNonUSPII}
                includeBankAccountType={includeBankAccountType}
                setIncludeBankAccountType={setIncludeBankAccountType}
                maxAttemptsTax={maxAttemptsTax}
                setMaxAttemptsTax={setMaxAttemptsTax}
                maxAttemptsBank={maxAttemptsBank}
                setMaxAttemptsBank={setMaxAttemptsBank}
                allowOtherNonValidatedTaxID={allowOtherNonValidatedTaxID}
                setAllowOtherNonValidatedTaxID={setAllowOtherNonValidatedTaxID}
                performNameComparison={performNameComparison}
                setPerformNameComparison={setPerformNameComparison}
                nameComparisonPassThreshold={nameComparisonPassThreshold}
                setNameComparisonPassThreshold={setNameComparisonPassThreshold}
                nameComparisonWarningThreshold={nameComparisonWarningThreshold}
                setNameComparisonWarningThreshold={setNameComparisonWarningThreshold}
              />
            )
          }}
        />
        <Route exact path="/pii/supplier/form" component={DataAccessForm} />
        <Route
          exact
          path="/pii/supplier/relishMessage"
          component={() => {
            return (
              <RelishMessage
                hist={props.hist}
                callBackFunction={callBackFunction}
                setCallBackFunction={setCallBackFunction}
                message={message}
                clientId={clientId}
                environmentId={environmentId}
                validationId={validationId}
                urlToken={urlToken}
              />
            )
          }}
        />
      </Switch>
    </Router>
  )
}
