import { title } from 'assets/jss/material-dashboard-pro-react.js'

const errorPageStyles = () => ({
  contentCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: '3',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    color: ["#081c3e", "#0a2657", "#0d3273", "#0f3a87", "#1044a3"],
    padding: '0 15px',
    width: '100%',
    maxWidth: '880px'
  },
  title: {
    ...title,
    fontSize: '13.7em',
    color: ["#081c3e"],
    letterSpacing: '14px',
    fontWeight: '700'
  },
  subTitle: {
    fontSize: '2.25rem',
    marginTop: '0',
    marginBottom: '8px'
  },
  description: {
    fontSize: '1.125rem',
    marginTop: '0',
    marginBottom: '8px'
  }
})

export default errorPageStyles
