function divideNameIntoFirstAndLast(name = '') {
  const trimmedName = name.toString().trim();
  const names = trimmedName.split(' ');

  const middleIndex = Math.ceil(names.length / 2);
  const firstName = names.slice(0, middleIndex).join(' ');
  const lastName = names.slice(middleIndex).join(' ');

  return { firstName, lastName };
}

export {
  divideNameIntoFirstAndLast
}