const AuthService = {
  Endpoint: process.env.REACT_APP_AUTH_SERVICE_DOMAIN
}

const AdditionalFileTypes = {
  TAX_OCR: 'taxOCR',
  ADDITIONAL_FILE: 'additionalFILE',
  BANK_DOCUMENTATION: 'bankDocumentation',
  TAX_DOCUMENTATION: 'taxDocumentation'
}

const InvoicesAIApi = {
  EndPoint: process.env.REACT_APP_INVOICE_AI_API_URL
}

const HeaderFieldsSide = {
  VENDOR_NAME: {
    pos: 0,
    section: 'vendorInfo',
    label: 'VENDOR NAME',
    type: 'VENDOR_NAME'
  },
  TOTAL: { pos: 4, section: 'invoiceAmount', label: 'TOTAL', type: 'TOTAL' },
  RECEIVER_ADDRESS: {
    pos: 0,
    section: 'addressInfo',
    label: 'SHIP-TO ADDRESS',
    type: 'RECEIVER_ADDRESS'
  },
  INVOICE_RECEIPT_DATE: {
    pos: 1,
    section: 'invoiceData',
    label: 'INVOICE DATE',
    type: 'INVOICE_RECEIPT_DATE'
  },
  INVOICE_RECEIPT_ID: {
    pos: 0,
    section: 'invoiceData',
    label: 'INVOICE NUMBER',
    type: 'INVOICE_RECEIPT_ID'
  },
  PAYMENT_TERMS: {
    pos: 2,
    section: 'vendorInfo',
    label: 'PAYMENT TERMS',
    type: 'PAYMENT_TERMS'
  },
  SUBTOTAL: {
    pos: 0,
    section: 'invoiceAmount',
    label: 'SUBTOTAL',
    type: 'SUBTOTAL'
  },
  TAX: { pos: 2, section: 'invoiceAmount', label: 'TAX', type: 'TAX' },
  DISCOUNT: {
    pos: 1,
    section: 'invoiceAmount',
    label: 'DISCOUNT',
    type: 'DISCOUNT'
  },
  OTHER_CHARGES: {
    pos: 3,
    section: 'invoiceAmount',
    label: 'OTHER CHARGES',
    type: 'OTHER_CHARGES'
  },
  BILL_TO: {
    pos: 1,
    section: 'addressInfo',
    label: 'BILL-TO ADDRESS',
    type: 'BILL_TO'
  },
  COMPANY_CODE: {
    pos: 2,
    section: 'invoiceData',
    label: 'COMPANY CODE',
    type: 'COMPANY_CODE'
  },
  PURCHASE_ORDER: {
    pos: 3,
    section: 'invoiceData',
    label: 'PURCHASE ORDER',
    type: 'PURCHASE_ORDER'
  },
  NON_PO: {
    pos: 4,
    section: 'invoiceData',
    label: 'NON-PO INVOICE?',
    type: 'NON_PO'
  },
  CURRENCY: {
    pos: 5,
    section: 'invoiceData',
    label: 'CURRENCY',
    type: 'CURRENCY'
  },
  INVOICE_TYPE: {
    pos: 0,
    section: 'start',
    label: 'INVOICE TYPE',
    type: 'INVOICE_TYPE'
  },
  DUE_DATE: { pos: 0, label: 'DUE DATE', type: 'DUE_DATE' },
  TAX_PAYER_ID: {
    pos: 3,
    section: 'vendorInfo',
    label: 'VENDOR TAX ID',
    type: 'TAX_PAYER_ID'
  },
  TAX_RECEIVER_ID: {
    pos: 0,
    section: 'taxInfo',
    label: 'BUYER TAX ID',
    type: 'TAX_RECEIVER_ID'
  },
  SHIP_TO: { pos: 0, label: 'SHIP-TO ADDRESS', type: 'SHIP_TO' },
  AUTHORIZATION_NUMBER: {
    pos: 2,
    section: 'taxInfo',
    label: 'AUTHORIZATION NUMBER',
    type: 'AUTHORIZATION_NUMBER'
  },
  SERIAL_NUMBER: {
    pos: 3,
    section: 'taxInfo',
    label: 'SERIAL NUMBER',
    type: 'SERIAL_NUMBER'
  },
  PROCESS: { pos: 1, section: 'start', label: 'PROCESS', type: 'PROCESS' }
}

const ColumnsOrderLineItems = {
  LINE_NUMBER: { pos: 0, label: 'Invoice Line' },
  ITEM: { pos: 2, section: 'invoiceAmount', label: 'Description' },
  QUANTITY: { pos: 4, label: 'Quantity' },
  PRICE: { pos: 10, label: 'Amount' },
  SUPPLIER_PART_NUMBER: { pos: 3, label: 'Supplier Part' },
  UNIT_OF_MEASURE: { pos: 5, label: 'Unit of Measure' },
  UNIT_PRICE: { pos: 6, label: 'Unit Price' },
  DISCOUNT: { pos: 7, label: 'Discount' },
  TAX_AMOUNT: { pos: 8, label: 'Tax Amount' },
  TAX_PERCENTAGE: { pos: 9, label: 'Tax %' },
  PO_LINE_NUMBER: { pos: 1, label: 'PO Line' }
}

const ColumnsOrderAdditionalCharges = {
  CHARGE_TYPE: { pos: 1, label: 'Type' },
  DESCRIPTION: { pos: 2, label: 'Description' },
  TOTAL: { pos: 3, label: 'Amount' }
}

const Taxes = {
  salesTax: { value: 'salesTax', label: 'Sales Tax' },
  pstTax: { value: 'pstTax', label: 'PST Tax' },
  gstTax: { value: 'gstTax', label: 'GST Tax' },
  hstTax: { value: 'hstTax', label: 'HST Tax' },
  vatTax: { value: 'vatTax', label: 'Vat' }
}

const AdditionalCharges = {
  handlingCharge: { value: 'handlingCharge', label: 'Handling Charges' },
  shippingCharges: { value: 'shippingCharges', label: ' Shipping Charges' }
}

const SpecialPOLines = [
  Taxes.salesTax,
  Taxes.pstTax,
  Taxes.gstTax,
  Taxes.hstTax,
  Taxes.vatTax,
  AdditionalCharges.shippingCharges,
  AdditionalCharges.handlingCharge
]

const specialPOLinesByProcess = {
  ocr: [Taxes.salesTax, Taxes.pstTax, Taxes.gstTax, Taxes.hstTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  'ocr-global': [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  mexicanValidation: [Taxes.vatTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  gtValidation: [AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  brValidation: [Taxes.vatTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge],
  svValidation: [Taxes.vatTax, AdditionalCharges.shippingCharges, AdditionalCharges.handlingCharge]
}

const piiValidationUpdateOptions = {
  attachment: 'attachmentSection',
  bank: 'banksValidation',
  tin: 'Tin-Name Validation',
  all: 'All'
}
const PIIStatus = {
  NEW: 'New',
  PENDING: 'Pending',
  INVITED: 'Invited',
  OPENED: 'Opened',
  SUBMITTED: 'Submitted',
  INTEGRATED: 'Integrated',
  BLOCKED: 'Blocked',
  REJECTED: 'Rejected',
  OBSOLETE: 'Obsolete',
  TOKEN_USED: 'Token used',
  TOKEN_EXPIRED: 'Token expired',
  NOT_INVITED: 'Not invited',
  IN_PROCESS: 'In Progress'
}
const piiValidationTypes = {
  bank: 'banksValidation',
  bankAccountOwnership: 'bankAccountOwnership',
  tin: 'Tin-Name Validation',
  giact: 'giact',
  file: 'fileValidation',
  attachment: 'attachmentSection'
}
const piiValidationInputFormatMessages = {
  bank: {
    swiftCode: {
      regex: /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
      message:
        'The input should begin with four uppercase letters, followed by two more uppercase letters, and then two characters, which can be either uppercase letters or digits. Optionally, it may have an additional three characters, which can also be either uppercase letters or digits.'
    },
    nonUSaccountNumber: {
      regex: /^[A-Z0-9-]+$/,
      message: 'The input should consist of one or more letters (A to Z), digits (0 to 9).'
    },
    USaccountNumber: {
      regex: /^\d{4,17}$/,
      message: 'The input should consist of a number between 4 and 17 digits.'
    },
    bankName: {
      regex: /^[A-Za-z0-9 -]+$/,
      message: 'The input should only consist of letters (A to Z), digits (0 to 9)'
    },
    ibanNumber: {
      regex: /^[A-Z]{2}\d{2}[A-Z0-9]{4,30}$/,
      message:
        'The input should start with exactly two uppercase letters (A to Z), followed by two digits (0 to 9), and then have between 4 to 30 characters consisting of uppercase letters or digits (A to Z, 0 to 9).'
    },
    USbankCode: {
      regex: /^\d{9}$/,
      message: 'The input should be exactly nine digits.'
    },
    nonUSbankCode: {
      regex: /^[A-Za-z0-9-]+$/,
      message: 'The input should consist of any combination of letters (A to Z), digits (0 to 9).'
    }
  },
  tin: {
    usSsnNumber: {
      regex: /^(\d{3}-\d{2}-\d{4}|\d{9})$/,
      message:
        'The input should match the format of a Social Security Number (SSN), which consists of three digits, a hyphen, two more digits, another hyphen, and finally four digits or a 9 digit input without hyphen'
    },
    usEinNumber: {
      regex: /^(\d{2}-\d{7}|\d{9})$/,
      message:
        'The input should match the format of a numerical code. It must start with two digits, followed by a hyphen, and then have seven more digits.'
    },
    nonUsTaxNumber: {
      regex: /^[A-Za-z0-9-]+$/,
      message: 'The input should consist of uppercase letters (A to Z), digits (0 to 9).'
    }
  }
}

const ApiPii = {
  EndPoint: process.env.REACT_APP_PII_URL
}

const InternalApi = {
  EndPoint: process.env.REACT_APP_INTERNAL_API_URL
}

const InvoiceProcess = {
  ocr: 'North America',
  'ocr-global': 'Unassigned',
  mexicanValidation: 'Mexico',
  gtValidation: 'Guatemala',
  brValidation: 'Brazil',
  svValidation: 'El Salvador'
}

const BankAccountOwnershipProvider = {
  DATA_ASSURE: 'BAOVDA',
  GIACT: 'GIACT'
};

const NameComparisonCodes = {
  ACCOUNT_HOLDER_NAME: {
    MATCH: 'PiiAccountHolderNameComparisonMatch',
    NOT_MATCH: 'PiiAccountHolderNameComparisonDoesNotMatch'
  },
  LEGAL_NAME: {
    MATCH: 'PiiNameComparisonMatch',
    PARTIAL_MATCH: 'PiiNameComparisonPartialMatch',
    NOT_MATCH: 'PiiNameComparisonDoesNotMatch'
  },
  DBA_NAME: {
    MATCH: 'PiiDbaNameComparisonMatch',
    PARTIAL_MATCH: 'PiiDbaNameComparisonPartialMatch',
    NOT_MATCH: 'PiiDbaNameComparisonDoesNotMatch'
  }
}

export {
  AdditionalCharges,
  AdditionalFileTypes,
  ApiPii,
  AuthService,
  BankAccountOwnershipProvider,
  ColumnsOrderAdditionalCharges,
  ColumnsOrderLineItems,
  HeaderFieldsSide,
  InternalApi,
  InvoiceProcess,
  InvoicesAIApi,
  NameComparisonCodes,
  PIIStatus,
  piiValidationInputFormatMessages,
  piiValidationTypes,
  piiValidationUpdateOptions,
  SpecialPOLines,
  specialPOLinesByProcess,
  Taxes
}
