import React, { useEffect } from 'react'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// utils
import { showLoading, hideLoading } from 'utils/functions'

export default function RelishMessageView (props) {
  //const useStyles = makeStyles(styles)
  // const classes = useStyles()
  const [agree, setAgree] = React.useState(false)
  const [loadedMessage, setLoadedMessage] = React.useState(false)
  const [agreementVersion, setAgreementVersion] = React.useState(0)
  const [message, setMessage] = React.useState('')
  const [checkMessage, setCheckMessage] = React.useState('I acknowledge that I have read and understood the above policies and procedures')
  const [checkButtonTitle, setCheckButtonTitle] = React.useState('Agree')
  //Component did mount
  useEffect(() => {
    getMessage(props.message)
  }, [props])
  async function getMessage (messageParam) {
    showLoading()
    // console.log('response message', { context: messageParam })
    if (messageParam && messageParam.success) {
      setLoadedMessage(true)
      //message body
      if (messageParam.data.message.body) setMessage(messageParam.data.message.body)
      //version
      if (messageParam.data.message.version) setAgreementVersion(messageParam.data.message.version)
      //agreement check properties
      if (messageParam.data.message.checkAgreement) {
        const checkProperties = messageParam.data.message.checkAgreement
        if (checkProperties.checkLabel) setCheckMessage(checkProperties.checkLabel)
        if (checkProperties.checkButtonTitle) setCheckButtonTitle(checkProperties.checkButtonTitle)
      }
    } else {
      if (messageParam) {
        let message = messageParam.message
        if (!message) {
          message = 'Something went wrong, please try again later.'
        }
      }
    }
    hideLoading()
  }
  const btnHandler = async () => {
    //console.log('New value for agree btnHandler: ' + agree)
    showLoading()
    props.callBackFunction.onclick(props.clientId, props.environmentId, props.validationId, agreementVersion, props.urlToken)
    hideLoading()
  }

  const handleChange = event => {
    setAgree(event.target.checked)
    console.log('New value for agree checkbox handler: ' + agree)
  }

  return (
    <div>
      <div className="container">
        {loadedMessage ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ padding: '0% 20% 0px' }}>
                <CardHeader color="info" icon></CardHeader>
                <CardBody>
                  <div className="jumbotron">
                    <div dangerouslySetInnerHTML={{ __html: message }}></div>
                  </div>
                  <form onSubmit={btnHandler} style={{ justifyContent: 'center !important' }}>
                    <div className="checkbox" style={{ marginTop: '2%' }}>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={agree} onChange={handleChange} name="checkAgreement" color="default" />}
                          label={checkMessage}
                        />
                      </FormGroup>
                    </div>
                    <div
                      style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: '2%'
                      }}
                    >
                      <Button color="primary" variant="contained" disabled={!agree} size="large" onClick={btnHandler}>
                        {checkButtonTitle}
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
