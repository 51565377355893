import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export function ConfirmAlertDialog(props) {
  const { openModal, alertTitle, alertDescription, handleClose } = props

  const defaultHandleClose = response => {
    console.log('Alert response:', response)
  }

  const handleClosure = handleClose || defaultHandleClose

  return (
    <Dialog open={openModal} maxWidth={'md'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{`${alertTitle}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{`${alertDescription}`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClosure(false)} color="secondary">
          Close
        </Button>
        <Button onClick={() => handleClosure(true)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
