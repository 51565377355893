import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { ReactComponent as Timer } from 'assets/img/time.svg'
import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles.js'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function ErrorPage() {
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <Timer style={{ width: '25rem', height: '20rem' }} />
          <GridContainer md={12} alignItems="center" justify="center">
            <GridItem>
              <h2 className={classes.subTitle}>Your session has expired</h2>
            </GridItem>
          </GridContainer>
          <h4 className={classes.description}>
            Access is allowed only for registered users
          </h4>
          <Button
            onClick={() => history.push(`/invoices-ai/supplierException`)}
            color="primary"
            type="button"
            variant="outlined"
            className={classes.btnColorPrimary}
            style={{ marginRight: '5px' }}
          >
            RETURN LOGIN PAGE
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}
